import React from "react";
import { Button, Card, Row, Col } from "antd";
import { StopOutlined } from "@ant-design/icons";
import {  useNavigate, useParams } from "react-router-dom";
import { PaymentForm } from "./Payment";
import { format } from "numerable";
import dayjs from "dayjs";

const PayCancel: React.FC = () => {
  const navigate = useNavigate();
  const [cart, setCart] = React.useState<PaymentForm | undefined>(undefined);
  const { refid } = useParams<{ refid: string }>(); 
  const fetchRef = () => {
    const urls = [
      `https://his-api-test.saintlouis.or.th/pay-link/checkout/${refid}`,
    ];

    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Ijc0MzYiLCJpZCI6IjFiYjkwMTRmLWVmYjctNDEwOS04ZGE4LTZiMDIxYmQ4ODdlYiIsInVzZXJJZCI6IjFiYjkwMTRmLWVmYjctNDEwOS04ZGE4LTZiMDIxYmQ4ODdlYiIsInJvbGVzIjpbIm51cnNlIl0sInJvbGUiOiJudXJzZSIsImRvY3RvckNvZGUiOm51bGwsInN1YiI6IjFiYjkwMTRmLWVmYjctNDEwOS04ZGE4LTZiMDIxYmQ4ODdlYiIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJudXJzZSJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJudXJzZSIsIngtaGFzdXJhLXVzZXItaWQiOiIxYmI5MDE0Zi1lZmI3LTQxMDktOGRhOC02YjAyMWJkODg3ZWIiLCJ4LWhhc3VyYS11c2VybmFtZSI6Ijc0MzYifSwiaWF0IjoxNzIyODI1NTYxLCJleHAiOjE3MjI5MTE5NjF9.c8hEJoPsbfjiuQAZAvbkPyog-1zTmrEEY8_FSlS8d3g";

    Promise.all(
      urls.map((url) =>
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
      )
    )
      .then((data) => {
        setCart(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  React.useEffect(() => {
    fetchRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refid]);

  return (
    <div className="max-w-8xl mx-auto">
      <Card style={{ border: "none" }}>
        <div className="border">
          <div className="relative mb-4">
            <div className="absolute left-0 top-0 h-full w-1 bg-red-500" style={{ zIndex: 1 }}></div>
            <div className="bg-red-100 border border-red-100 text-black-700 pl-4 py-3 rounded relative" style={{ marginLeft: '1px', zIndex: 0 }}>
              <StopOutlined className="font-bold mr-2 text-lg text-red-500 " />
              <strong className="font-bold">Payment has cancelled</strong>
              <span className="block sm:inline"> {dayjs(new Date()).format("DD MMMM HH:mm")}</span>
            </div>
          </div>
          <Row gutter={[16, 16]} className="flex-col-reverse lg:flex-row justify-center p-2">
          <Col xs={24} lg={12}>
            <div className="text-sm ">
              <h2 className="text-xl font-bold mb-10">Summary</h2>
              <div className="bg-blue-100 pl-2 pt-2 pb-2 mb-1 ">
                <span>Detail</span>
              </div>
                  <div
                    style={{display:cart?.orderRef1 ? "":"none"}}
                    className="flex justify-between mb-1 pl-2 mb-3"
                  >
                    <span>Reference No #{1}:</span>
                    <span>{cart?.orderRef1}</span>
                  </div>
                  <div
                    style={{display:cart?.orderRef2 ? "":"none"}}
                    className="flex justify-between mb-1 pl-2 mb-3"
                  >
                    <span>Reference No #{2}:</span>
                    <span>{cart?.orderRef2}</span>
                  </div>
                  <div
                    style={{display:cart?.orderRef3 ? "":"none"}}
                    className="flex justify-between mb-1 pl-2 mb-3"
                  >
                    <span>Reference No #{3}:</span>
                    <span>{cart?.orderRef3}</span>
                  </div>
              <div className="flex justify-between border-b border-gray-300 pb-2 pl-2 mb-3">
                <span>Amount:</span>
                <span>{format(cart?.amount,"0,0.00") || 0 } THB</span>
              </div>
              <div className="flex justify-between mb-1 pl-2 mb-3">
                <span>Detail:</span>
                <span className="text-gray-400 pl-2 mb-3">
                  {cart?.remark}
                </span>
              </div>
              <div className="pl-2 flex justify-between mb-1 border-b border-gray-300 pb-2">
                <span>Customer name:</span>
                <span>{cart?.name}</span>
              </div>
            </div>
            <Button onClick={() => navigate("/"+{refid})} type="primary" className="w-full mt-4 bg-blue-900 p-5 mb-7">
                Try again
              </Button>
          </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default PayCancel;